
import Company from "@/entities/domain/Company/Company";
import getImageFromSource from "@/utils/getImageFromSouce";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "HeaderComponent",
	props: {
		company: {
			type: Object as PropType<Company>,
			default: {} as Company,
		},
	},
	computed: {
		brandLogoImageSource() {
			return getImageFromSource(
				this.company?.FrontendDesign?.BrandLogoImage ??
					"@/assets/img/logo_tailorproduct.svg"
			);
		},
	},
});
