
import Company from "@/entities/domain/Company/Company";
import AddToStyling from "@/utils/AddToStyling";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "InfoWilli",
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		confirmText: {
			type: String,
			required: true,
		},
		williImage: {
			type: String,
			default: "/img/2209_avatar_willi_hand_weiß.svg",
		},
		top: {
			type: Boolean,
			default: false,
		},
		bottom: {
			type: Boolean,
			default: false,
		},
		left: {
			type: Boolean,
			default: false,
		},
		right: {
			type: Boolean,
			default: false,
		},
		company: {
			type: Object as PropType<Company>,
		}
	},
	mounted() {
		const css = `
			#ap-productfinder .tooltip::after{
				background-image: url("${process.env.VUE_APP_FILE_SPACE}img/bubblearrow.svg");
			}
		`;

		AddToStyling(css)
	},
	methods: {
		infoAcknowledged() {
			this.$emit("close");
		},
		getWilliImage(src) {
			if (src[0] === "/") {
				return require("@/assets" + src);
			}

			return src;
		},
	},
});
