import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12159878"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.open)
        ? _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["infoWilli", { top: _ctx.top, bottom: _ctx.bottom, left: _ctx.left, right: _ctx.right }])
          }, [
            _createElementVNode("img", {
              src: _ctx.getWilliImage(_ctx.williImage)
            }, null, 8, _hoisted_1),
            _createElementVNode("div", {
              class: "tooltip",
              style: _normalizeStyle({color: _ctx.company.FrontendDesign?.PrimaryFontColor === '#FFFFFF' ? '#333333' : _ctx.company.FrontendDesign?.PrimaryFontColor})
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true),
              _createElementVNode("div", {
                class: "confirm",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.infoAcknowledged && _ctx.infoAcknowledged(...args)))
              }, _toDisplayString(_ctx.confirmText), 1)
            ], 4)
          ], 2)), [
            [_directive_click_outside, _ctx.infoAcknowledged]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}